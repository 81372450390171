import logo from "./logo.webp"

const  clientData = {
   client_entity: 5,
   attorney_firm: 'cpsllcms.com',
    attorney_name: 'CJ Fox',
    attorney_number: '877-432-0921',
    attorney_email: 'cjfox@cpsllcms.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_cps+(720p).mp4',
    mainColor: 'white',
    secondaryColor: '#9d5718',
    siteLink: 'http://cpsllcms.com',
    logo
}

export default clientData